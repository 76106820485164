// i18next-extract-mark-ns-start capabilities-emissions

import { EMISSIONS_NAV, LINKS } from '@utils/constants';
import { graphql } from 'gatsby';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import Button from '@components/Button';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import ResourceListing from '@components/views/ResourceListing';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';


const CapabilityEmissionsPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation(); return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="standard"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content">
						<h2>Reducing propulsion system emissions</h2>

						<p>
							The number 1 mobility industry priority is to cut emissions with the ultimate goal of achieving net zero emissions from the propulsion system.
							Driven by poor air quality and global warming effects, Original Equipment Manufacturers (OEMs) and manufacturers must comply with increasingly
							stringent regulations to emit fewer pollutants including Nitrous-Oxide (NOx) and Carbon Monoxide (emitted as greenhouse gas CO2)
							as well as Ammonia and Formaldehyde to name but two. Additionally brake and tyre dust must be limited alongside exhaust gas particulates.
						</p>
						<p>
							This comes as Euro5+ regulations are due for motorcycles in 2024 and Euro 7 for vehicles from 2025, with the target of net zero cars and vans by 2035.
							In parallel, the marine industry is aiming for global shipping to cut emissions by over 50% before 2050.
						</p>
						<p>
							Emissions regulations apply regardless of whether the propulsion unit is running on petrol, diesel, hydrogen, alternative fuels (e-fuels),
							electricity or a hybrid system, leaving OEMs and manufacturers to decide the right design path for their vehicles and applications.
						</p>
						<p>
							In the initial stages of development, physical prototype modelling is expensive,
							and this is where simulation software is vital to answer the ‘What ifs’ before committing to a design path.
							Realis Simulation software models exhaust emissions, often in real-time showing the effects of different fuel types,
							calibration scenarios and drive cycle performance on overall system emissions.
						</p>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_CuttingEmissions")}
						className="widget__sibling__pages">
						<AssetList>
							{EMISSIONS_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
					<Link
						to={LINKS.ABOUT_CONTACT}
						className="button--more">
						{t("_GetInTouch")}
					</Link>
				</Aside>
			</PageSection>

			<ResourceListing
				resources={data.resources}
				title={t("_OurInsights")}
				layout="fullwidth"
				actions={
					<div className="text--center">
						<Button to={LINKS.INSIGHTS} type="more">
							{t("_SeeAllInsights")}
						</Button>
					</div>
				}
			/>

		</Page>
	);
};

export default CapabilityEmissionsPage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["capabilities-emissions", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/capabilities/emissions/Realis-Simulation_1325_motorcycleExhaust_cropped.jpg" }) {
		...imageBreaker
	}
	resources: allMarkdownRemark(
		filter: {
			fileAbsolutePath: { regex: "/resources/" }
			fields: { language: { eq: $language } }
			frontmatter: { capabilities: { in: "Emissions" } }
		}
		sort: { fields: frontmatter___date, order: DESC }
		limit: 9
	) {
		nodes {
			fields {
				slug
			}
			frontmatter {
				title
				summary
				image {
					...imageStandard
				}
				type
			}
		}
	}
}
`;
